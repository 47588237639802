import { gql, useMutation, useQuery } from '@apollo/client'
import React, { useContext, useMemo } from 'react'
import { FormProvider, useForm } from "react-hook-form"

import AssetsDialog from '../../components/assets/dialog'
import AutoSave from '../../components/autosave'
import { useTitle } from '../../components/browser'
import Errors from '../../components/errors'
import Fieldset from '../../components/form/fieldset'
import Input from '../../components/form/input'
import FormSections from '../../components/form/sections'
import Slug from '../../components/form/slug'
import Layout from '../../components/layout'
import LayoutCompass from '../../components/layout/compass'
import LayoutContent from '../../components/layout/content'
import NotificationContext from '../../components/notifications/context'
import SectionEditingStatus from '../../components/record/editing-status'
import RecordPublishButton from '../../components/record/publish-button'
import RecordTimestamps from '../../components/record/timestamps'
import RecordUnpublishButton from '../../components/record/unpublish-button'
import RecordsDialog from '../../components/records/dialog'
import StatusBadge from '../../components/status/badge'
import cookbookPostFragment from '../../fragments/cookbook-post'
import { requireAuthenticatedEditor } from '../../services/authentication'

const GET_COOKBOOK_POST_QUERY = gql`
  query GetCookbookPost($id: ID!) {
    cookbookPost(id: $id) {
      ${cookbookPostFragment}
    }
  }
`

const UPDATE_COOKBOOK_POST_MUTATION = gql`
  mutation UpdateCookbookPostMutation($id: ID!, $titleDe: String, $titleEn: String, $slugDe: String, $slugEn: String, $teaserDe: String, $teaserEn: String, $coverImage: ID, $metaTitleDe: String, $metaTitleEn: String, $metaDescriptionDe: String, $metaDescriptionEn: String, $keywordsDe: String, $keywordsEn: String ) {
    updateCookbookPost(input: { id: $id, titleDe: $titleDe, titleEn: $titleEn, slugDe: $slugDe, slugEn: $slugEn, teaserDe: $teaserDe, teaserEn: $teaserEn, coverImage: $coverImage, metaTitleDe: $metaTitleDe, metaTitleEn: $metaTitleEn, metaDescriptionDe: $metaDescriptionDe, metaDescriptionEn: $metaDescriptionEn, keywordsDe: $keywordsDe, keywordsEn: $keywordsEn }) {
      cookbookPost {
        ${cookbookPostFragment}
      }
    }
  }
`
export default function PageForm({ id }) {
  requireAuthenticatedEditor()

  const { addNotification } = useContext(NotificationContext)
  const methods = useForm()
  const { loading, error, data } = useQuery(GET_COOKBOOK_POST_QUERY,
    {
      variables: { id },
      onCompleted: (data) => methods.reset(data.cookbookPost)
    }
  )
  const [mutate, { loading: mutating }] = useMutation(UPDATE_COOKBOOK_POST_MUTATION, {
    onError: error => {
      console.error({ error })
      addNotification({ title: 'Error updating cookbook post', message: 'The cookbook post could not be updated.', type: 'error' })
    }
  })

  const title = useMemo(() => `${data?.cookbookPost?.titleDe} - Cookbook Post`, [data])
  useTitle(title)

  if (error) {
    console.error(error)
    return <div>There was an error loading the cookbook post.</div>
  }

  const onSubmit = () => { }

  return (
    <Layout>
      <LayoutCompass
        label="cookbookPost"
        title={title}
        backTo='/cookbook-posts'
      />
      <LayoutContent loading={loading}>
        <FormProvider {...methods}>
          <div className="flex justify-between space-x-6">
            <div className="bg-white rounded-lg shadow flex-1 p-6">
              <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col space-y-8 flex-1">
                <Errors errors={methods.formState.errors} />
                <Fieldset>
                  <Input
                    label="Title"
                    name="titleDe"
                    register={methods.register}
                  />
                </Fieldset>
                <Fieldset>
                  <Slug
                    label="Slug"
                    name="slugDe"
                    control={methods.control}
                  />
                </Fieldset>
                <Fieldset>
                  <AssetsDialog
                    label='Cover Image'
                    value={data?.cookbookPost?.coverImage}
                    methods={methods}
                    name="coverImage.id"
                  />
                </Fieldset>
                <Fieldset>
                  <AssetsDialog
                    label='Audio File One'
                    value={data?.cookbookPost?.audioFile}
                    methods={methods}
                    name="audioFile.id"
                  />
                  <AssetsDialog
                    label='Audio File Two'
                    value={data?.cookbookPost?.audioFileTwo}
                    methods={methods}
                    name="audioFileTwo.id"
                  />
                  <AssetsDialog
                    label='Audio File Three'
                    value={data?.cookbookPost?.audioFileThree}
                    methods={methods}
                    name="audioFileThree.id"
                  />
                </Fieldset>
                <Fieldset>
                  <RecordsDialog
                    label='Recipe'
                    value={data?.cookbookPost?.recipe}
                    methods={methods}
                    name="recipe.id"
                    emptyLabel="No recipe selected."
                    query={`
                      query GetRecipes($search: String) {
                        records: allRecipes(first: 200, search: $search) {
                          edges {
                            node {
                              __typename
                              id
                              titleDe
                              editingStatus
                            }
                          }
                        }
                      }
                    `}
                  />
                </Fieldset>
                <Fieldset>
                  <FormSections
                    recordId={data?.cookbookPost?.id}
                    sections={data?.cookbookPost?.sections}
                  />
                </Fieldset>
              </form>
            </div>
            <AutoSave
              id={id}
              mutate={mutate}
              control={methods.control}
            />
            <div className="w-3/12 bg-white shadow rounded-lg sticky top-24 self-start space-y-4 p-6 text-gray-500">
              <div className="flex justify-between items-center">
                <div className="font-bold">Status</div>
                <StatusBadge status={data?.cookbookPost?.editingStatus} />
              </div>
              <RecordPublishButton
                record={data?.cookbookPost}
                label="cookbook post"
                methods={methods}
              />
              <RecordUnpublishButton
                record={data?.cookbookPost}
                label="cookbook post"
                methods={methods}
              />
              <SectionEditingStatus
                isDirty={methods.formState.isDirty}
                mutating={mutating}
              />
              <RecordTimestamps
                record={data?.cookbookPost}
              />
            </div>
          </div>
        </FormProvider>
      </LayoutContent>
    </Layout>
  )
}
